import React from 'react';
import { makeStyles, Grid, Box, Typography } from '@material-ui/core';
import { Link } from 'gatsby';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { styleConfig } from '../../config/config';
import MuiButton from './MuiButton';
import Title from './Common/Title';

const NewsCard: React.FC<NewsCardProps> = ({
  permalink,
  date,
  title,
  content,
  image,
  isReverse,
  isDark,
}) => {
  const placeholder = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "car-placeholder.jpg" }) {
        id
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `);
  const useStyles = makeStyles({
    content: {
      position: 'relative',
      height: '100%',
      width: '100%',
      padding: '90px',
      background: isDark ? styleConfig.color.bg.darkgrey : styleConfig.color.bg.white,

      '& small': {
        display: 'block',
        marginBottom: '1rem',

        color: isDark ? styleConfig.color.text.gray : styleConfig.color.text.gray,
        fontFamily: styleConfig.fonts.body,
        fontWeight: 400,
      },

      '& h3': {
        color: isDark ? styleConfig.color.text.white : styleConfig.color.text.darkgrey,
      },

      '& h5': {
        color: isDark ? styleConfig.color.text.white : styleConfig.color.text.darkgrey,
      },
    },

    excerpt: {
      display: 'block',
      marginBottom: '4rem',
    },
    image: {
      position: 'relative',
      display: 'block',
      height: '100%',
      width: '100%',
      overflow: 'hidden',
      minHeight: 773,

      '& img': {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        objectPosition: 'center center',
      },
    },
  });

  const classes = useStyles();

  return (
    <Grid container direction={isReverse ? 'row-reverse' : 'row'}>
      <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
        <div className={classes.content}>
          <small>{date}</small>
          <Title titleText={title} titleSize="h5" component="h5" />

          <Box component="div" className={classes.excerpt}>
            <Typography
              component="div"
              dangerouslySetInnerHTML={{ __html: content }}
              key="newsCard-content"
              variant="body1"
            />
          </Box>

          <Link aria-label="Read more" to={`/newsroom/${permalink}/`}>
            <MuiButton aria-label="Read more" name="read-more" variant="outlined" labelColor="#FFF">
              Read more
            </MuiButton>
          </Link>
        </div>
      </Grid>

      <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
        <GatsbyImage
          className={classes.image}
          image={image || placeholder.file.childImageSharp.gatsbyImageData}
          alt={title}
        />
      </Grid>
    </Grid>
  );
};

interface NewsCardProps {
  permalink: string;
  date: string;
  title: string;
  content: string;
  image: IGatsbyImageData;
  isReverse?: boolean;
  isDark?: boolean;
}

export default NewsCard;
