import React from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import { useStaticQuery, graphql } from 'gatsby';
import get from 'lodash/get';
import NewsCard from './NewsCard';

const ContentModuleLatestNews: React.FunctionComponent<ContentModuleLatestNewsProps> = (props) => {
  const { limit } = props;

  const postData = useStaticQuery(graphql`
    {
      allWordpressPost(limit: 10) {
        edges {
          node {
            date(formatString: "MMMM")
            excerpt
            id
            title
            slug
            acf {
              featured_image {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const useStyles = makeStyles({
    root: {
      width: '100%',
      position: 'relative',
    },
    noPadding: {
      paddingLeft: '0!important',
      paddingRight: '0!important',
    },
  });

  const classes = useStyles();
  return (
    postData && (
      <section className={classes.root}>
        <Grid container>
          {postData.allWordpressPost.edges.map((item, index) => {
            // Do nothing if the post limit is reached
            if (index + 1 > limit) {
              return null;
            }

            if (index % 2) {
              return (
                <React.Fragment key={`cm-latest-news-${index}`}>
                  <NewsCard
                    content={item.node.excerpt}
                    date={item.node.date}
                    image={get(
                      item,
                      'node.acf.featured_image.localFile.childImageSharp.gatsbyImageData'
                    )}
                    isDark
                    isReverse
                    permalink={item.node.slug}
                    title={item.node.title}
                  />
                </React.Fragment>
              );
            }

            return (
              <React.Fragment key={`cm-latest-news-${index}`}>
                <NewsCard
                  content={item.node.excerpt}
                  date={item.node.date}
                  image={get(
                    item,
                    'node.acf.featured_image.localFile.childImageSharp.gatsbyImageData'
                  )}
                  isDark
                  permalink={item.node.slug}
                  title={item.node.title}
                />
              </React.Fragment>
            );
          })}
        </Grid>
      </section>
    )
  );
};

interface ContentModuleLatestNewsProps {
  limit: number;
}

export default ContentModuleLatestNews;
