import React from 'react';
import loadable from '@loadable/component';
import ContentModuleLatestNews from './ContentModuleLatestNews';

const LazyContentModuleBrandBlocks = loadable(() => import('./ContentModuleBrandBlocks'));
const LazyContentModuleFaq = loadable(() => import('./ContentModuleFaqList'));
const LazyContentModuleImageSlider = loadable(() => import('./ContentModuleImageSlider'));
const LazyContentModuleImageText = loadable(() => import('./ContentModuleImageText'));
const LazyContentModuleImageTextMultiple = loadable(
  () => import('./ContentModuleImageTextMultiple')
);
const LazyContentModuleIntro = loadable(() => import('./ContentModuleIntro'));
const LazyContentModuleIntroFeatures = loadable(() => import('./ContentModuleIntroFeatures'));
const LazyContentModuleIntroSteps = loadable(() => import('./ContentModuleIntroSteps'));
const LazyContentModuleLargeLinks = loadable(() => import('./ContentModuleLargeLinks'));
const LazyContentModuleMagazineBlocks = loadable(() => import('./ContentModuleMagazineBlock'));
const LazyContentModuleReviewFaq = loadable(() => import('./ContentModuleReviewFaq'));
const LazyContentModulesCallToActionPrimary = loadable(
  () => import('./ContentModuleCallToActionPrimary')
);
const LazyContentModulesGeneralContent = loadable(() => import('./ContentModuleGeneralContent'));
const LazyContentModuleSpecialOffers = loadable(() => import('./ContentModuleSpecialOffers'));
const LazyContentModuleMultiImageBlocks = loadable(() => import('./ContentModuleMultiImageBlocks'));
const LazyContentContentModuleReviews = loadable(() => import('./ContentModuleReviews'));

const renderContentModule = (module) => {
  switch (module.__typename) {
    case 'WordPressAcf_general_content':
      return (
        <LazyContentModulesGeneralContent
          extraContent={module.supporting_content}
          mainContent={module.main_content}
        />
      );

    case 'WordPressAcf_call_to_action':
      return (
        <LazyContentModulesCallToActionPrimary
          buttons={module.buttons}
          headline={module.title}
          image={module.image.localFile}
          subtitle={module.pre_title}
        />
      );

    case 'WordPressAcf_latest_news':
      return <ContentModuleLatestNews limit={module.number_of_posts} />;

    case 'WordPressAcf_slider':
      return (
        <LazyContentModuleImageSlider
          slides={module.slides}
          subtitle={module.pre_title}
          title={module.title}
        />
      );

    case 'WordPressAcf_image_with_content':
      return (
        <LazyContentModuleImageText
          dark={module.colour_mode === 'dark'}
          isReverse={module.image_align === 'right'}
          panelContent={module.blockContent}
          panelImage={module.image}
          panelTitle={module.title}
        />
      );

    case 'WordPressAcf_reviews':
      return (
        <LazyContentContentModuleReviews
          isDark={module.is_dark}
          preTitle={module.pre_title}
          title={module.title}
        />
      );

    case 'WordPressAcf_accordion_faq':
      return (
        <LazyContentModuleFaq
          dark={module.colour_mode === 'dark'}
          faqlist={module.items}
          hasButton={module.add_contact_button}
          headline={module.title}
          subtitle={module.pre_title}
        />
      );

    case 'WordPressAcf_accordion_faq_review':
      return (
        <LazyContentModuleReviewFaq
          faqs={module.items}
          hasButton={module.add_contact_button}
          review={module.featured_review}
          subtitle={module.pre_title}
          title={module.title}
        />
      );

    case 'WordPressAcf_intro':
      return (
        <LazyContentModuleIntro
          content={module.intro}
          headline={module.title}
          subtitle={module.pre_title}
        />
      );

    case 'WordPressAcf_intro_steps':
      return (
        <LazyContentModuleIntroSteps
          buttons={module.overview.buttons}
          content={module.content.blockContent}
          headline={module.overview.title}
          steps={module.content.steps}
          subheading={module.overview.pre_title}
        />
      );

    case 'WordPressAcf_intro_features':
      return (
        <LazyContentModuleIntroFeatures
          content={module.description}
          headline={module.intro}
          title={module.title}
          hasPipe
          usplist={[
            {
              icon: 'handout',
              label: 'No Fees or Charges',
            },
            {
              icon: 'road',
              label: 'Road Tax Included',
            },
            {
              icon: 'keys',
              label: 'Free Mainland UK Delivery',
            },
          ]}
        />
      );

    case 'WordPressAcf_large_links':
      return <LazyContentModuleLargeLinks items={module.blocks} />;

    case 'WordPressAcf_brand_blocks':
      return (
        <LazyContentModuleBrandBlocks
          headline={module.title}
          rows={module.rows}
          subtitle={module.pre_title}
        />
      );

    case 'WordPressAcf_multi_image_blocks':
      return (
        <LazyContentModuleMultiImageBlocks
          headline={module.title}
          rows={module.mib_rows}
          subtitle={module.pre_title}
        />
      );

    case 'WordPressAcf_magazine_blocks':
      return <LazyContentModuleMagazineBlocks rows={module.rows} />;

    case 'WordPressAcf_special_offers':
      return (
        <LazyContentModuleSpecialOffers
          subtitle={module.pre_title}
          tabs={module.tabs}
          title={module.title}
        />
      );

    case 'WordPressAcf_image_with_content_multiple':
      return (
        <LazyContentModuleImageTextMultiple title={module.title} submodules={module.submodules} />
      );

    default:
      return null;
  }
};

const ContentModules: React.FunctionComponent<ContentModulesProps> = (props) => {
  const { module, index, ...rest } = props;

  return (
    <div className="content-modules" id={`module-${index + 1}`} {...rest}>
      {renderContentModule(module)}
    </div>
  );
};

interface ContentModulesProps {
  module: any;
  index: number;
}

export default ContentModules;
